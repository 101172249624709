import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

const api = axios.create({
  // dev
  // baseURL: 'http://localhost:8080/',

  // pro
  baseURL: '/',
  withCredentials: true,

  // headers: {
  //   'Accept': 'application / json',
  //   'Content - Type': 'application / json'
  // }
})
Vue.prototype.$http = api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
